import Keycloak from 'keycloak-js';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from './env';

export const keycloak = Keycloak({
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
});

export async function login(): Promise<void> {
  await keycloak.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/check-sso.html',
    pkceMethod: 'S256',
  });

  if (!keycloak.authenticated) {
    await keycloak.login();
  }
}
