import container from '@/bootstrap';
import { ROOT_COMPONENT } from './features/core/container';
import { login } from './keycloak';

void (async () => {
  await login();

  const get = await container();
  const root = get(ROOT_COMPONENT);
  new root({ propsData: { container: get } }).$mount('#app');
})();
